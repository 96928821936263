import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Modal,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Typography,
  Tooltip,
  Badge,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import TipsAndUpdatesOutlinedIcon from "@mui/icons-material/TipsAndUpdatesOutlined";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../reducers/login.reducer";
import { selectUserInfo } from "../selectors/login.selector";
import { setLeftSideNavigationSelected } from "../reducers/navigation.reducer";
import { selectLeftSideNavigationSelected } from "../selectors/navigation.selector";
import { ComponentStyles } from "../styles";
import LatestUpdateModal from "./latestUpdateModal.component";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import { getListOfAuditsInCapManagement } from "../reducers/audit.reducer";
import { selectNumberOfInProgressCAPs } from "../selectors/audit.selector";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import NotificationModal from "./AuditDetails/notifications.component";
import { getNotifications } from "../reducers/misc.reducer";
import { selectNotifications } from "../selectors/misc.selector";

const LeftSideNavbar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();

  const userInfo = useSelector(selectUserInfo);
  const selected = useSelector(selectLeftSideNavigationSelected);

  const handleLogout = () => {
    dispatch(logout());
    navigate("/");
  };

  const handleNavigate = (path) => {
    dispatch(setLeftSideNavigationSelected(path));
    dispatch(
      getListOfAuditsInCapManagement({
        organisationId: userInfo.organisationId,
      })
    );
    navigate(path);
  };
  useEffect(() => {
    if (params["*"] !== "suppliers") {
      dispatch(setLeftSideNavigationSelected(`/${params["*"]}`));
    }
  }, [params]);

  const [UpdateModalOpen, setUpdateModalOpen] = React.useState(false);

  const handleModalOpen = () => {
    setUpdateModalOpen(true);
  };

  const handleModalClose = () => {
    setUpdateModalOpen(false);
  };
  const numberOfInProgressCAPs = useSelector(selectNumberOfInProgressCAPs);
  // const [notificationModalOpen, setNotificationModalOpen] =
  //   React.useState(false);

  // const handleNotificationModalOpen = () => {
  //   setNotificationModalOpen(true);
  // };

  // const handleNotificationModalClose = () => {
  //   setNotificationModalOpen(false);
  // };

  // useEffect(() => {
  //   dispatch(getNotifications());
  // }, []);
  // const notifications = useSelector(selectNotifications);

  // Replace sidebarWidth object with a single width
  const sidebarWidth = 210;

  return (
    <Box
      // Remove mouse events
      sx={{
        maxWidth: sidebarWidth,
        minWidth: sidebarWidth,
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        padding: 2,
        borderRight: "1px solid #cccccc4a",
        borderBottom: "none",
        borderLeft: "none",
        borderTop: "none",
        backgroundColor: "white",
        // Remove transition
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between", // Always show full width
          px: "10px",
          py: 2,
        }}
      >
        <img 
          src="/Elm_Logo.svg" 
          alt="Logo" 
          style={{
            ...ComponentStyles.logoImage,
            width: '30px', // Fixed width
          }} 
        />
        {/* {isExpanded && notifications?.length > 0 && (
          <Box sx={{ display: "flex", gap: 1 }}>
            <Tooltip title="Supplier Notes">
              <IconButton
                onClick={handleNotificationModalOpen}
                sx={{
                  width: 40,
                  height: 40,
                  padding: 0,
                }}
              >
                <Badge badgeContent={notifications?.length || 0} color="error">
                  <NotificationsOutlinedIcon
                    style={{ color: "#667085", fontSize: "32px" }}
                  />
                </Badge>
              </IconButton>
            </Tooltip>
          </Box>
        )} */}
      </Box>

      <List sx={{ width: "100%" }}>
        {/* <ListItemButton
          onClick={() => handleNavigate(`/suppliers`)}
          selected={selected === "/suppliers"}
          sx={{
            bgcolor: selected === "/suppliers" ? "action.selected" : "inherit",
            borderRadius: "12px",
            marginY: 0.5,
          }}
        >
          <ListItemIcon style={{ minWidth: 40 }}>
            <img
              src="/suppliers.svg"
              alt="Suppliers"
              style={ComponentStyles.navbarListItemIcon}
            />
          </ListItemIcon>
          {isExpanded && (
            <ListItemText
              primary="Suppliers"
              primaryTypographyProps={{
                sx: ComponentStyles.navbarListItemText,
              }}
            />
          )}
        </ListItemButton> */}
        <ListItemButton
          onClick={() => handleNavigate(`/factories`)}
          selected={selected === "/factories"}
          sx={{
            bgcolor: selected === "/factories" ? "action.selected" : "inherit",
            borderRadius: "12px",
            marginY: 0.5,
          }}
        >
          <ListItemIcon style={{ minWidth: 40 }}>
            <img
              src="/factories.svg"
              alt="Factories"
              style={ComponentStyles.navbarListItemIcon}
            />
          </ListItemIcon>
          <ListItemText
            primary="CMA"
            primaryTypographyProps={{
              sx: ComponentStyles.navbarListItemText,
            }}
          />
        </ListItemButton>
        <ListItemButton
          onClick={() => handleNavigate(`/upstreamfactories`)}
          selected={selected === "/upstreamfactories"}
          sx={{
            bgcolor: selected === "/upstreamfactories" ? "action.selected" : "inherit",
            borderRadius: "12px",
            marginY: 0.5,
          }}
        >
          <ListItemIcon style={{ minWidth: 40 }}>
            <img
              src="/factories.svg"
              alt="Factories"
              style={ComponentStyles.navbarListItemIcon}
            />
          </ListItemIcon>
          <ListItemText
            primary="Upstream"
            primaryTypographyProps={{
              sx: ComponentStyles.navbarListItemText,
            }}
          />
        </ListItemButton>
        <ListItemButton
          onClick={() => handleNavigate(`/insights`)}
          selected={selected === "/insights"}
          sx={{
            bgcolor: selected === "/insights" ? "action.selected" : "inherit",
            borderRadius: "12px",
            marginY: 0.5,
          }}
        >
          <ListItemIcon style={{ minWidth: 40 }}>
            <img
              src="/insights.svg"
              alt="Insights"
              style={ComponentStyles.navbarListItemIcon}
            />
          </ListItemIcon>
          <ListItemText
            primary="Insights"
            primaryTypographyProps={{
              sx: ComponentStyles.navbarListItemText,
            }}
          />
        </ListItemButton>

        {/* <ListItemButton
          onClick={() => handleNavigate(`/dueAction`)}
          selected={selected === "/dueAction"}
          sx={{
            bgcolor: selected === "/dueAction" ? "action.selected" : "inherit",
            borderRadius: "12px",
            marginY: 0.5,
          }}
        >
          <ListItemIcon style={{ minWidth: 40 }}>
            <img
              src="/dueAction.svg"
              alt="Action Due"
              style={ComponentStyles.navbarListItemIcon}
            />
          </ListItemIcon>
          <ListItemText
            primary="Action Due"
            primaryTypographyProps={{
              sx: ComponentStyles.navbarListItemText,
            }}
          />
        </ListItemButton> */}
        {/* <ListItemButton
          onClick={() => handleNavigate(`/capmanagement`)}
          selected={selected === "/capmanagement"}
          sx={{
            bgcolor:
              selected === "/capmanagement" ? "action.selected" : "inherit",
            borderRadius: "12px",
            marginY: 0.5,
          }}
        >
          <ListItemIcon style={{ minWidth: 40 }}>
            <img
              src="/capmanagement.svg"
              alt="CAP"
              style={ComponentStyles.navbarListItemIcon}
            />
          </ListItemIcon>
          {isExpanded && (
            <ListItemText
              primaryTypographyProps={{
              sx: ComponentStyles.navbarListItemText,
            }}
            primary={
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <span>CAPs</span>
                <Badge
                  badgeContent={numberOfInProgressCAPs}
                  color="error"
                  sx={{ marginLeft: 2, mr: 0 }}
                />
              </Box>
            }
            />
          )}
        </ListItemButton> */}
        <ListItemButton
          onClick={() => handleNavigate(`/resources`)}
          selected={selected === "/resources"}
          sx={{
            bgcolor: selected === "/resources" ? "action.selected" : "inherit",
            borderRadius: "12px",
          }}
        >
          <ListItemIcon style={{ minWidth: 40 }}>
            <img
              src="/resources.svg"
              alt="Resources"
              style={ComponentStyles.navbarListItemIcon}
            />
          </ListItemIcon>
          <ListItemText
            primary="Resources"
            primaryTypographyProps={{
              sx: ComponentStyles.navbarListItemText,
            }}
          />
        </ListItemButton>
      </List>

      <Box sx={{ flexGrow: 1 }} />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          px: "10px",
          py: 2,
        }}
      >
        <Box>
          <Typography variant="body2" style={{ fontWeight: 600 }}>
            {userInfo.name}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {/* {userInfo.email} */}
          </Typography>
        </Box>
        <IconButton
          edge="end"
          aria-label="Logout"
          onClick={() => handleLogout()}
        >
          <img
            src="/logout.svg"
            alt="Logout"
            style={ComponentStyles.navbarListItemIcon}
          />
        </IconButton>
      </Box>
      {/* <NotificationModal
        open={notificationModalOpen}
        onClose={handleNotificationModalClose}
      /> */}
    </Box>
  );
};

export default LeftSideNavbar;
