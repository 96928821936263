import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  Grid,
  Button,
  TextField,
  Typography,
  Card,
  Divider,
  CardContent,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getValidData } from "../../../utils/lib";
import { updateFactoryData } from "../../../reducers/factory.reducer";
import { selectSupplierNameFromId } from "../../../selectors/supplier.selector";
import { ComponentStyles } from "../../../styles";
// import { updateSupplier } from '../../actions/supplierActions'; // Ensure you have this action
// import CommentSection from "../../commentSection.component";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import IconButton from "@mui/material/IconButton";

const FactoryInformationEditModal = ({
  open = false,
  onClose,
  internalData,
}) => {
  const initData = {
    factoryId: getValidData(internalData?.metadata?.factoryId),
    alias: getValidData(internalData?.metadata?.alias),
    address: getValidData(internalData?.metadata?.address),
    country: getValidData(internalData?.metadata?.country),
    city: getValidData(internalData?.metadata?.city),
    zipCode: getValidData(internalData?.metadata?.zipCode),
    status: getValidData(internalData?.metadata?.status),
    confirmedNextAuditDate: getValidData(internalData?.metadata?.confirmedNextAuditDate),
    contactPersonName: getValidData(internalData?.metadata?.contactPerson?.name),
    contactPersonPosition: getValidData(internalData?.metadata?.contactPerson?.position),
    contactPersonPhone: getValidData(internalData?.metadata?.contactPerson?.phone),
    contactPersonEmail: getValidData(internalData?.metadata?.contactPerson?.email),
    productSupportName: getValidData(internalData?.metadata?.productSupport?.name),
    productSupportEmail: getValidData(internalData?.metadata?.productSupport?.email),
    businessUnit: getValidData(internalData?.businessUnit),
    tmls: getValidData(internalData?.metadata?.tmls),
    influence: getValidData(internalData?.metadata?.influence),
    sharedVBU: getValidData(internalData?.metadata?.sharedVBU),
    agreementType: getValidData(internalData?.metadata?.agreementType),
    cmaConnection: getValidData(internalData?.metadata?.cmaConnection),
  };
  const [formData, setFormData] = useState(initData);

  // Store initial data to compare against
  const [initialData, setInitialData] = useState({});

  useEffect(() => {
    setInitialData(initData);
    setFormData(initData);
  }, [internalData]);

  const dispatch = useDispatch();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const changes = Object.keys(formData).reduce((acc, key) => {
      if (formData[key] !== initialData[key]) {
        acc[key] = formData[key];
      }
      return acc;
    }, {});

    if (Object.keys(changes).length > 0) {
      dispatch(
        updateFactoryData({
          organisationId: internalData?.organisationId,
          supplierId: internalData?.supplierId,
          factoryId: internalData?._id,
          changes,
        })
      );
    }
    onClose();
  };

  const supplierName = useSelector((state) =>
    selectSupplierNameFromId(state, internalData?.supplierId)
  );

  const handleContactChange = (index, field, value) => {
    const newContacts = [...formData.additionalContacts];
    newContacts[index] = {
      ...newContacts[index],
      [field]: value,
    };
    setFormData({
      ...formData,
      additionalContacts: newContacts,
    });
  };

  const addContact = () => {
    const newContacts = Array.isArray(formData.additionalContacts)
      ? formData.additionalContacts
      : [];
    setFormData({
      ...formData,
      additionalContacts: [
        ...newContacts,
        {
          contactPersonName: "",
          contactPersonPosition: "",
          contactPersonPhone: "",
          contactPersonEmail: "",
        },
      ],
    });
  };

  const removeContact = (index) => {
    const newContacts = formData.additionalContacts.filter(
      (_, i) => i !== index
    );
    setFormData({
      ...formData,
      additionalContacts: newContacts,
    });
  };
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "90%",
          maxWidth: 1200,
          maxHeight: "90%",
          overflowY: "auto",
          p: 4,
          boxShadow:
            "0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1)",
          border: "none",
          borderRadius: "12px",
          py: 2,
          bgcolor: "background.paper",
        }}
      >
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Card
                sx={{
                  p: 4,
                  pb: 8,
                  boxShadow: "none",
                  border: "none",
                  borderRadius: "12px",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Grid
                  container
                  spacing={2}
                  justifyContent="space-between"
                  sx={{ mb: 2 }}
                >
                  <Grid item>
                    <Typography
                      variant="h5"
                      gutterBottom
                      sx={{
                        fontWeight: 700,
                        fontSize: 16,
                        color: "#344054",
                        display: "inline-block",
                      }}
                    >
                      Edit factory information
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Button
                      type="button"
                      onClick={onClose}
                      sx={{
                        mr: 2,
                        borderRadius: "8px",
                        textTransform: "none",
                        color: "#585aeb",
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{
                        backgroundColor: "#585aeb",
                        borderRadius: "8px",
                        textTransform: "none",
                      }}
                    >
                      Save Changes
                    </Button>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  justifyContent="space-between"
                  sx={{ mb: 2 }}
                >
                  <Grid item xs={4}>
                    <Typography>
                      <span style={ComponentStyles.fieldName}>Suppliers:</span>{" "}
                      <Typography
                        style={{
                          fontWeight: 600,
                          fontSize: 16,
                          color: "#3538CD",
                          display: "flex",
                          textDecoration: "none",
                        }}
                      >
                        {supplierName}
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      select
                      fullWidth
                      label="Status"
                      name="status"
                      variant="outlined"
                      value={formData.status || ""}
                      onChange={handleChange}
                      margin="normal"
                      SelectProps={{
                        native: true,
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderRadius: "8px",
                          },
                        },
                      }}
                    >
                      <option value="">Select status</option>
                      <option value="active">Active</option>
                      <option value="inactive">Inactive</option>
                    </TextField>
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ mb: 2 }}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Address"
                      name="address"
                      variant="outlined"
                      value={formData.address}
                      onChange={handleChange}
                      margin="normal"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderRadius: "8px", // Set the border-radius here
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      label="City"
                      name="city"
                      variant="outlined"
                      value={formData.city}
                      onChange={handleChange}
                      margin="normal"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderRadius: "8px",
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      label="Country"
                      name="country"
                      variant="outlined"
                      value={formData.country}
                      onChange={handleChange}
                      margin="normal"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderRadius: "8px",
                          },
                        },
                      }}
                    />
                  </Grid>
                 
                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      label="Zip Code"
                      name="zipCode"
                      variant="outlined"
                      value={formData.zipCode}
                      onChange={handleChange}
                      margin="normal"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderRadius: "8px",
                          },
                        },
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2} justifyContent="space-between">
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Contact Person"
                      name="contactPersonName"
                      variant="outlined"
                      value={formData.contactPersonName}
                      onChange={handleChange}
                      margin="normal"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderRadius: "8px", // Set the border-radius here
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Position"
                      name="contactPersonPosition"
                      variant="outlined"
                      value={formData.contactPersonPosition}
                      onChange={handleChange}
                      margin="normal"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderRadius: "8px", // Set the border-radius here
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Phone"
                      name="contactPersonPhone"
                      variant="outlined"
                      value={formData.contactPersonPhone}
                      onChange={handleChange}
                      margin="normal"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderRadius: "8px", // Set the border-radius here
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Email"
                      name="contactPersonEmail"
                      variant="outlined"
                      value={formData.contactPersonEmail}
                      onChange={handleChange}
                      margin="normal"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderRadius: "8px", // Set the border-radius here
                          },
                        },
                      }}
                    />
                  </Grid>
                  
                </Grid>
                <Divider sx={{ mt: 3, mb: 3 }} />
                {formData?.additionalContacts &&
                  formData?.additionalContacts?.map((contact, index) => (
                    <>
                      <Grid container spacing={2} key={index} sx={{ mb: 2 }}>
                        <Grid item xs={12}>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              variant="subtitle1"
                              sx={{ fontWeight: 600 }}
                            >
                              Contact Person {index + 1}
                            </Typography>
                            {/* {index > 0 && (
                          <IconButton
                            onClick={() => removeContact(index)}
                            sx={{ ml: 1 }}
                          >
                            <DeleteOutlineIcon />
                          </IconButton>
                        )} */}
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            fullWidth
                            label="Contact Person"
                            value={contact.contactPersonName}
                            onChange={(e) =>
                              handleContactChange(
                                index,
                                "contactPersonName",
                                e.target.value
                              )
                            }
                            margin="normal"
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                  borderRadius: "8px",
                                },
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            fullWidth
                            label="Position"
                            value={contact.contactPersonPosition}
                            onChange={(e) =>
                              handleContactChange(
                                index,
                                "contactPersonPosition",
                                e.target.value
                              )
                            }
                            margin="normal"
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                  borderRadius: "8px",
                                },
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            fullWidth
                            label="Phone"
                            value={contact.contactPersonPhone}
                            onChange={(e) =>
                              handleContactChange(
                                index,
                                "contactPersonPhone",
                                e.target.value
                              )
                            }
                            margin="normal"
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                  borderRadius: "8px",
                                },
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            fullWidth
                            label="Email"
                            value={contact.contactPersonEmail}
                            onChange={(e) =>
                              handleContactChange(
                                index,
                                "contactPersonEmail",
                                e.target.value
                              )
                            }
                            margin="normal"
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                  borderRadius: "8px",
                                },
                              },
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Divider sx={{ mt: 3, mb: 3 }} />
                    </>
                  ))}
                <Grid item xs={12}>
                  <Button
                    startIcon={<AddCircleOutlineIcon />}
                    onClick={addContact}
                    sx={{
                      color: "#585aeb",
                      textTransform: "none",
                      "&:hover": {
                        backgroundColor: "rgba(88, 90, 235, 0.04)",
                      },
                    }}
                  >
                    Add Another Contact
                  </Button>
                </Grid>
                {/* Add Divider and Operation Information section */}
                {/* <Grid item xs={12}>
                  <Divider sx={{ mt: 4, mb: 2 }} />
                  <Typography
                    variant="h5"
                    gutterBottom
                    sx={{
                      fontWeight: 700,
                      fontSize: 16,
                      color: "#344054",
                      display: "inline-block",
                      mb: 2,
                    }}
                  >
                    Operation Information
                  </Typography>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Confirmed Next Audit Date"
                      name="confirmedNextAuditDate"
                      type="date"
                      variant="outlined"
                      value={formData.confirmedNextAuditDate || ""}
                      onChange={handleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderRadius: "8px",
                          },
                        },
                      }}
                    />
                  </Grid>
                </Grid> */}
                <Divider sx={{ mt: 4, mb: 2 }} />
                <Typography variant="h6" gutterBottom>
                  Product Support Information
                </Typography>
                
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Product Support Name"
                      name="productSupportName"
                      value={formData.productSupportName}
                      onChange={(e) => setFormData({
                        ...formData,
                        productSupportName: e.target.value
                      })}
                      margin="normal"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderRadius: "8px",
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Product Support Email"
                      name="productSupportEmail"
                      value={formData.productSupportEmail}
                      onChange={(e) => setFormData({
                        ...formData,
                        productSupportEmail: e.target.value
                      })}
                      margin="normal"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderRadius: "8px",
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Business Unit"
                      name="businessUnit"
                      value={formData.businessUnit}
                      onChange={handleChange}
                      margin="normal"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderRadius: "8px",
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="TMLs"
                      name="tmls"
                      value={formData.tmls}
                      onChange={handleChange}
                      margin="normal"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderRadius: "8px",
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Influence"
                      name="influence"
                      value={formData.influence}
                      onChange={handleChange}
                      margin="normal"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderRadius: "8px",
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Shared VBU"
                      name="sharedVBU"
                      value={formData.sharedVBU}
                      onChange={handleChange}
                      margin="normal"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderRadius: "8px",
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Agreement Type"
                      name="agreementType"
                      value={formData.agreementType}
                      onChange={handleChange}
                      margin="normal"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderRadius: "8px",
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="CMA Connection"
                      name="cmaConnection"
                      value={formData.cmaConnection}
                      onChange={handleChange}
                      margin="normal"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderRadius: "8px",
                          },
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Modal>
  );
};

export default FactoryInformationEditModal;
