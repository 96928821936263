import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Box,
  Card,
  Divider,
  CardContent,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { selectFactoryData } from "../../selectors/factory.selector";
import { selectAuditData } from "../../selectors/audit.selector";
import { getFactoryById } from "../../reducers/factory.reducer";
import { useParams } from "react-router-dom";
import moment from "moment";
import AuditInformationEditModal from "./EditModals/editAuditInformation.component";
import { selectEditModeState } from "../../selectors/misc.selector";
import EditIcon from "@mui/icons-material/Edit";
import { AuditStyles, ComponentStyles } from "../../styles";

const AuditInformation = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const selectedAuditData = useSelector(selectAuditData);
  const selectedFactoryData = useSelector(selectFactoryData);
  const [factoryData, setFactoryData] = useState(selectedFactoryData);
  const [isEditingInternal, setIsEditingInternal] = useState(false);

  const editMode = useSelector(selectEditModeState);

  useEffect(() => {
    if (
      selectedAuditData &&
      ((selectedFactoryData?._id &&
        selectedAuditData?.factoryId !== selectedFactoryData?._id) ||
        !selectedFactoryData)
    ) {
      dispatch(
        getFactoryById({
          supplierId: params.supplierId,
          factoryId: selectedAuditData.factoryId,
        })
      );
    }
    setFactoryData(selectedFactoryData);
  }, [selectedFactoryData, selectedAuditData]);

  const toggleEditInternal = () => {
    setIsEditingInternal(!isEditingInternal);
  };

  return (
    factoryData &&
    (!editMode ? (
      <Box sx={AuditStyles.containerBox}>
        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          sx={AuditStyles.containerGrid}
        >
          <Grid item>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <Typography
                sx={{
                  ...AuditStyles.headingsNameBold700,
                  display: "inline-block",
                }}
              >
                Audit Information
              </Typography>
              {selectedAuditData?.isHistoricalAudit && (
                <Typography
                  sx={{
                    backgroundColor: '#ffffff',
                    border: '1px solid #666',
                    padding: '4px 8px',
                    borderRadius: '4px',
                    fontSize: '0.875rem',
                    color: '#666',
                  }}
                >
                  Imported Audit
                </Typography>
              )}
            </Box>
          </Grid>
          {editMode && (
            <EditIcon
              fontSize={"2"}
              sx={ComponentStyles.editButton}
              onClick={toggleEditInternal}
            />
          )}

          <Grid item>
            <Card variant="outlined" style={AuditStyles.editModeCard}>
              <CardContent
                style={{
                  padding: "4px 32px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography style={AuditStyles.headingsNameBold700}>
                  Risk Score
                </Typography>
                <Divider
                  orientation="vertical"
                  flexItem
                  style={{ margin: "16px 16px" }}
                />
                <Typography style={AuditStyles.bigHeadingFont30}>
                  {selectedAuditData?.risk?.auditScore || "-"}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        {/* Company Address */}
        <Grid container spacing={2} sx={AuditStyles.containerGrid}>
          <Grid item xs={6}>
            <Typography sx={AuditStyles.fieldName}>Audit Scheme</Typography>
            <Typography sx={AuditStyles.fieldValue}>
              {selectedAuditData?.metadata?.auditType || selectedAuditData?.metadata?.facilityData?.auditing_standard || "-"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography sx={AuditStyles.fieldName}>CAP Start Date</Typography>
            <Typography sx={AuditStyles.fieldValue}>
              {selectedAuditData?.metadata?.facilityData?.cap_start_date
                ? moment(selectedAuditData?.metadata?.facilityData?.cap_start_date).format(
                    "MM/DD/YYYY"
                  )
                : `${moment(selectedAuditData?.metadata?.auditEndDate).format(
                    "MM/DD/YYYY"
                  )} (Default as Audit Date)`}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={AuditStyles.containerGrid}>
        <Grid item xs={6}>
            <Typography sx={AuditStyles.fieldName}>Audit Start Date</Typography>
            <Typography sx={AuditStyles.fieldValue}>
              {(selectedAuditData?.metadata?.auditStartDate && 
                moment(selectedAuditData.metadata.auditStartDate).format("MM/DD/YYYY")) ||
               (selectedAuditData?.metadata?.facilityData?.audit_start && 
                moment(selectedAuditData.metadata.facilityData.audit_start, "MM/DD/YY").format("MM/DD/YYYY")) ||
               "-"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography sx={AuditStyles.fieldName}>Audit End Date</Typography>
            <Typography sx={AuditStyles.fieldValue}>
              {(selectedAuditData?.metadata?.auditEndDate && 
                moment(selectedAuditData.metadata.auditEndDate).format("MM/DD/YYYY")) ||
               (selectedAuditData?.metadata?.facilityData?.audit_end && 
                moment(selectedAuditData.metadata.facilityData.audit_end, "MM/DD/YY").format("MM/DD/YYYY")) ||
               "-"}
            </Typography>
          </Grid>
          
        </Grid>
        <Grid container spacing={2} sx={AuditStyles.containerGrid}>
        <Grid item xs={6}>
            <Typography sx={AuditStyles.fieldName}>Auditor Firm</Typography>
            <Typography sx={AuditStyles.fieldValue}>
              {selectedAuditData?.metadata?.auditFirm || 
               selectedAuditData?.metadata?.facilityData?.auditing_firm || "-"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography sx={AuditStyles.fieldName}>Auditor Name</Typography>
            <Typography sx={AuditStyles.fieldValue}>
              {selectedAuditData?.auditor || 
               selectedAuditData?.metadata?.facilityData?.auditor_name || "-"}
            </Typography>
          </Grid>
          
        </Grid>
        <Grid container spacing={2} sx={AuditStyles.containerGrid}>
        {/* <Grid item xs={6}>
            <Typography sx={AuditStyles.fieldName}>
              Language (Management)
            </Typography>
            <Typography sx={AuditStyles.fieldValue}>
              {selectedAuditData?.metadata?.primaryLanguage || 
               selectedAuditData?.metadata?.facilityData?.language_management || "-"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography sx={AuditStyles.fieldName}>
              Language (Workers)
            </Typography>
            <Typography sx={AuditStyles.fieldValue}>
              {selectedAuditData?.metadata?.workersLanguage || 
               selectedAuditData?.metadata?.facilityData?.language_workers || "-"}
            </Typography>
          </Grid> */}
        </Grid>
      </Box>
    ) : (
      <AuditInformationEditModal
        open={isEditingInternal}
        onClose={() => setIsEditingInternal(false)}
        factoryData={selectedFactoryData}
        auditData={selectedAuditData}
      />
    ))
  );
};

export default AuditInformation;