import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Typography,
  Drawer,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Pagination,
  Button,
  Divider,
  InputAdornment,
  Paper,
  Chip,
  Skeleton,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

import { debounce, set } from "lodash";
import moment from "moment";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import TopBreadcrumbs from "./topBreadcrumbs.component";
import {
  getListOfAllSuppliers,
  getSearchResultOfSuppliers,
  getSupplierById,
} from "../reducers/supplier.reducer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import {
  getFactoryById,
  getListOfAllFactories,
  getSearchResultOfFactories,
} from "../reducers/factory.reducer";
import { selectOrganisationId } from "../selectors/login.selector";
import {
  selectFactoryCache,
  selectFactoryList,
  selectSearchCount,
  selectSearchResultsFactoryList,
  selectTotalCount,
} from "../selectors/factory.selector";
import { selectLoadingState } from "../selectors/misc.selector";
import { getListOfAuditsInCapManagement } from "../reducers/audit.reducer";
import CreateFacilityForm from "./createFacility.component";

const FactoryTableDataGrid = ({ type }) => {
  const dispatch = useDispatch();
  const factoriesList = useSelector(selectFactoryList);
  const isLoading = useSelector(selectLoadingState);
  const totalCount = useSelector(selectTotalCount);

  const navigateToFactoryDetails = (supplierId, factoryId) => {
    dispatch(getFactoryById({ supplierId, factoryId }));
    dispatch(getSupplierById(supplierId));
    navigate(`/suppliers/${supplierId}/factory/${factoryId}`);
  };

  const mode = type;



  const rows = factoriesList
    .filter(factory => {
      // Filter out hidden factories
      if (factory?.metadata?.entityType === "hide") {
        return false;
      }
      if (mode === "CMA") {
        return factory?.metadata?.entityType !== 'upstream';
      } else if (mode === "upstream") {
        return factory?.metadata?.entityType === 'upstream';
      }
      return true; // Include all factories if mode is neither CMA nor upstream
    })
    .map((factory) => ({
      id: factory?.factoryId,
      factoryName: factory?.name,
      parentAccount: factory?.supplierName,
      country: factory?.location,
      businessUnit: factory?.businessUnit,
      inherentScore: factory?.inherentRisk || "",
      enhancedScore: factory?.auditScore ?? "",
      actionAlert: factory?.actionAlert,
      // segment: factory?.metadata?.segment,
      status: factory?.metadata?.status,
      supplierId: factory?.supplierId,
      organisationId: factory?.organisationId,
      accountManager: factory?.productSupport?.name,
      region: factory?.metadata?.region,
    }));

  const columns = [
    {
      field: "details",
      headerName: "",
      flex: 0.8,
      minWidth: 100,
      renderCell: (params) => (
        <Button
          variant="contained"
          onClick={() => navigateToFactoryDetails(params.row.supplierId, params.row.id)}
          sx={{
            backgroundColor: "#6172F3",
            color: "white",
            "&:hover": {
              backgroundColor: "#5667e2",
              fontWeight: "bold",
            },
            textTransform: "none",
            minWidth: "79px",
            height: "40px",
            borderRadius: "8px",
          }}
          size="small"
        >
          Details
        </Button>
      ),
    },
    { 
      field: "status", 
      headerName: "Status", 
      flex: 0.8,
      minWidth: 80,
      renderCell: (params) => (
        <Chip
          label={params.value === null || params.value === "" ? "active" : params.value}
          sx={{
            borderRadius: "16px",
            fontWeight: 500,
            fontSize: 12,
            backgroundColor: params.value === "inactive" ? "#FECDCA" : "#F2F4F7",
            color: params.value === "inactive" ? "#B42318" : "default",
          }}
        />
      ),
    },
    { field: "factoryName", headerName: "CMA Name", flex: 2, minWidth: 150 },
    { field: "parentAccount", headerName: "Parent Account", flex: 1.5, minWidth: 100 },
    { field: "region", headerName: "Region", flex: 1, minWidth: 60 },
    { field: "businessUnit", headerName: "Business Unit", flex: 1, minWidth: 60 },
    { 
      field: "inherentScore", 
      headerName: "Abstract Score", 
      flex: 1,
      minWidth: 60
    },
    { 
      field: "enhancedScore", 
      headerName: "Enhanced Score", 
      flex: 1,
      minWidth: 60
    },
    { field: "accountManager", headerName: "Account Manager", flex: 1, minWidth: 160 },
    
  ];

  const initialState = {
    columns: {
      columnVisibilityModel: {
        // This can be used to hide or show columns by default
      },
    },
    filter: {
      filterModel: {
        items: [],
      },
    },
  };

  const dataGridFormat = {
    columns,
    rows,
    initialState,
  };

  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");

  const [filteredFactories, setFilteredFactories] = useState([]);
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

  const [count, setCount] = useState(totalCount);

  const factoryCache = useSelector((state) => selectFactoryCache(state, page));
  const navigate = useNavigate();

  const organisationId = useSelector(selectOrganisationId);
  const searchCount = useSelector(selectSearchCount);
  const searchResults = useSelector(selectSearchResultsFactoryList);

  useEffect(() => {
    if (searchTerm !== "") {
      setFilteredFactories(searchResults);
    } else {
      setFilteredFactories(factoriesList);
    }
  }, []);

  // commenting this code. This was for the old table.
  // useEffect(() => {
  //   if (factoryCache) {
  //     setFilteredFactories(factoryCache);
  //   } else {
  //     const limit = 8;
  //     const offset = (page - 1) * limit;

  //     dispatch(getListOfAllFactories({ page, limit, offset, organisationId }));
  //   }
  // }, [page, factoryCache]);

  useEffect(() => {
    if (organisationId) {
      dispatch(
        getListOfAllFactories({ page: 1, limit: 8, offset: 1, organisationId })
      );
      dispatch(getListOfAuditsInCapManagement({ organisationId }));
    }
  }, [organisationId]); // Runs when organisationId changes

  const debouncedFetchFactories = useCallback(
    debounce((term) => {
      dispatch(getSearchResultOfFactories({ search: term }));
    }, 100), // Delay in milliseconds
    []
  );

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  const location = useLocation();

  return (
    <Box sx={{ p: 3 }}>
      <TopBreadcrumbs />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Typography variant="h6" style={{ fontSize: 20, fontWeight: 600 }}>
            Factory
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            style={{ fontSize: 14, fontWeight: 400, marginBottom: 8 }}
          >
            Manage your Factory here
          </Typography>
        </div>
        {/* <Button
          variant="outlined"
          onClick={() => {
            setIsDrawerOpen(true);
          }}
          sx={{
            color: "#6172F3",
            "&:hover": {
              color: "white",
              backgroundColor: "#5667e2",
            },
            textTransform: "none",
            width: "200px",
            height: "40px",
            borderRadius: "8px",
            mr: "48px",
          }}
        >
          Create New Factory
        </Button> */}
      </div>
      <Divider />
      <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 1, mt: 2 }}>
        {/* <TextField
          label="Search supplier"
          value={searchTerm}
          onChange={handleSearch}
          variant="outlined"
          size="small"
          sx={{ width: "300px" }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <img src="./search.svg" alt="Search" />
              </InputAdornment>
            ),
          }}
        /> */}
      </Box>
      <div style={{ width: "100%", padding: "24px 48px" }}>
        <div style={{ height: "calc(100vh - 250px)", width: "100%" }}>
          {isLoading ? (
            <Box sx={{ width: "100%" }}>
              {[...Array(10)].map((_, index) => (
                <Skeleton
                  key={index}
                  height={60}
                  animation="wave"
                  sx={{ my: 1 }}
                />
              ))}
            </Box>
          ) : (
            <DataGrid
              {...dataGridFormat}
              disableColumnSelector
              disableDensitySelector
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                },
              }}
              getRowHeight={() => "auto"}
              sx={{
                borderRadius: "8px",
                padding: "16px",
                "& .MuiDataGrid-cell": {
                  padding: "8px",
                },
                "& .MuiDataGrid-row": {
                  maxHeight: "none !important",
                },
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: "#f0f0f0", // Light gray background
                  color: "#333", // Dark text color
                  fontWeight: "bold",
                },
                "& .MuiDataGrid-columnHeader": {
                  padding: "16px",
                },
                "& .MuiDataGrid-columnHeaderTitle": {
                  fontWeight: "bold",
                },
              }}
            />
          )}
        </div>
      </div>
      <Drawer
        anchor="bottom"
        open={isDrawerOpen}
        onClose={() => {
          setIsDrawerOpen(false);
        }}
        sx={{
          "& .MuiDrawer-paper": {
            borderTopLeftRadius: "16px",
            borderTopRightRadius: "16px",
            maxHeight: "98%",
            width: "90%",
            margin: "0 auto",
            display: "flex",
            justifySelf: "center",
          },
        }}
      >
        <CreateFacilityForm handleDrawerClose={handleDrawerClose} />
      </Drawer>
    </Box>
  );
};

export default FactoryTableDataGrid;
