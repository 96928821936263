import React from "react";
import { Box, Typography } from "@mui/material";

const FacilityEnvironmental = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "200px",
        p: 3,
      }}
    >
      <Typography
        sx={{
            fontWeight: 500,
            fontSize: 16,
            color: "#8B8D97",
          }}
      >
        Environmental data coming soon...
      </Typography>
    </Box>
  );
};

export default FacilityEnvironmental; 