import React, { useEffect, useState, useCallback } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

import {
  Box,
  Typography,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Pagination,
  Button,
  Divider,
  InputAdornment,
  Paper,
  Chip,
  Drawer,
} from "@mui/material";
import TopBreadcrumbs from "../topBreadcrumbs.component";

import { debounce } from "lodash";

import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import {
  getListOfAllSuppliers,
  getSearchResultOfSuppliers,
  getSupplierById,
} from "../../reducers/supplier.reducer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import {
  selectSearchCount,
  selectSearchResultsSupplierList,
  selectSupplierCache,
  selectSupplierList,
  selectTotalCount,
} from "../../selectors/supplier.selector";
import { getListOfAllFactories } from "../../reducers/factory.reducer";
import { BorderLeft } from "@mui/icons-material";
import CreateSupplierForm from "../createSupplier.component";

const SupplierTableDataGrid = () => {
  const dispatch = useDispatch();
  const suppliersList = useSelector(selectSupplierList);
  const navigateToSupplierDetails = (supplierId) => {
    dispatch(getSupplierById(supplierId));
    navigate(`${location.pathname}/${supplierId}`);
  };

  const rows = suppliersList
    ?.map((supplier) => {
      if (!supplier) return null;

      return {
        id: supplier?.supplierId || "",
        supplierName: supplier?.name || "",
        supplierIdFromMetadata: supplier?.metadata?.uniqueSupplierId || "–",
        country: supplier?.country || "",
        totalSiteCount: supplier?.factoryData?.totalFactories
          ? isNaN(Number(supplier.factoryData.totalFactories))
            ? null
            : Number(supplier.factoryData.totalFactories)
          : null,
        tier1: supplier?.factoryData?.tier1
          ? isNaN(Number(supplier.factoryData.tier1))
            ? null
            : Number(supplier.factoryData.tier1)
          : null,
        tier2: supplier?.factoryData?.tier2
          ? isNaN(Number(supplier.factoryData.tier2))
            ? null
            : Number(supplier.factoryData.tier2)
          : null,
        status: supplier?.status || "-",
      };
    })
    .filter(Boolean); // Remove any null rows
  const columns = [
    { field: "supplierName", headerName: "Supplier Name", flex: 2 },
    {
      field: "supplierIdFromMetadata",
      headerName: "Supplier ID",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "country",
      headerName: "Country",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "totalSiteCount",
      headerName: "Total Factory Count",
      type: "number",
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueFormatter: (params) => {
        if (!params) return "-";
        return params.value === null ? "-" : params.value;
      },
    },
    {
      field: "tier1",
      headerName: "Tier 1",
      type: "number",
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueFormatter: (params) => {
        if (!params) return "-";
        return params.value === null ? "-" : params.value;
      },
    },
    {
      field: "tier2",
      headerName: "Tier 2",
      type: "number",
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueFormatter: (params) => {
        if (!params) return "-";
        return params.value === null ? "-" : params.value;
      },
    },
    {
      field: "status",
      headerName: "Factory Status",
      flex: 0.75,
      renderCell: (params) => (
        <Chip
          label={params.value}
          sx={{
            borderRadius: "16px",
            fontWeight: 500,
            fontSize: 12,
            backgroundColor: params.value === "active" ? "#F2F4F7" : "#FEF0C7",
            color: params.value === "active" ? "#667085" : "#B54708",
            textTransform: "capitalize",
          }}
        />
      ),
    },
    {
      field: ".",
      headerName: "",
      flex: 1,
      renderCell: (params) => (
        <Button
          variant="contained"
          onClick={() => {
            navigateToSupplierDetails(params.row.id);
          }}
          sx={{
            backgroundColor: "#6172F3",
            color: "white",
            "&:hover": {
              backgroundColor: "#5667e2",
              fontWeight: "bold",
            },
            textTransform: "none",
            width: "79px",
            height: "40px",
            borderRadius: "8px",
          }}
          size="small"
        >
          Details
        </Button>
      ),
    },
  ];

  const initialState = {
    columns: {
      columnVisibilityModel: {
        // This can be used to hide or show columns by default
      },
    },
  };

  const dataGridFormat = {
    columns,
    rows,
    initialState,
  };

  const totalCount = useSelector(selectTotalCount);
  const searchResults = useSelector(selectSearchResultsSupplierList);
  const searchCount = useSelector(selectSearchCount);
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredSuppliers, setFilteredSuppliers] = useState([]);
  const [count, setCount] = useState(totalCount);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

  const supplierCache = useSelector((state) =>
    selectSupplierCache(state, page)
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (searchTerm !== "") {
      setCount(searchCount);
    } else {
      setCount(totalCount);
    }
  }, [totalCount, searchCount]);

  useEffect(() => {
    if (searchTerm !== "") {
      setFilteredSuppliers(searchResults);
    } else {
      setFilteredSuppliers(suppliersList);
    }
  }, []);

  useEffect(() => {
    if (supplierCache) {
      setFilteredSuppliers(supplierCache);
    } else {
      const limit = 8;
      const offset = (page - 1) * limit;
      dispatch(getListOfAllSuppliers({ page, limit, offset }));
    }
  }, [page, supplierCache, dispatch]);

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  const location = useLocation();

  return (
    <Box sx={{ p: 3 }}>
      <TopBreadcrumbs />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Typography variant="h6" style={{ fontSize: 20, fontWeight: 600 }}>
            Suppliers
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            style={{ fontSize: 14, fontWeight: 400, marginBottom: 8 }}
          >
            Manage your suppliers here
          </Typography>
        </div>
        <Button
          variant="outlined"
          onClick={() => {
            setIsDrawerOpen(true);
          }}
          sx={{
            color: "#6172F3",
            "&:hover": {
              color: "white",
              backgroundColor: "#5667e2",
            },
            textTransform: "none",
            width: "200px",
            height: "40px",
            borderRadius: "8px",
            mr: "48px",
          }}
        >
          Create New Supplier
        </Button>
      </div>
      <Divider />
      <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 1, mt: 2 }}>
        {/* <TextField
          label="Search supplier"
          value={searchTerm}
          onChange={handleSearch}
          variant="outlined"
          size="small"
          sx={{ width: "300px" }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <img src="./search.svg" alt="Search" />
              </InputAdornment>
            ),
          }}
        /> */}
      </Box>
      <div style={{ width: "100%", padding: "24px 48px" }}>
        <div style={{ height: "calc(100vh - 250px)", width: "100%" }}>
          <DataGrid
            {...dataGridFormat}
            disableColumnSelector
            disableDensitySelector
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
            getRowHeight={() => "auto"}
            sx={{
              borderRadius: "8px",
              padding: "16px",
              "& .MuiDataGrid-cell": {
                padding: "8px",
              },
              "& .MuiDataGrid-row": {
                maxHeight: "none !important",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "#f0f0f0", // Light gray background
                color: "#333", // Dark text color
                fontWeight: "bold",
              },
              "& .MuiDataGrid-columnHeader": {
                padding: "16px",
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                fontWeight: "bold",
              },
            }}
          />
        </div>
      </div>
      <Drawer
        anchor="bottom"
        open={isDrawerOpen}
        onClose={() => {
          setIsDrawerOpen(false);
        }}
        sx={{
          "& .MuiDrawer-paper": {
            borderTopLeftRadius: "16px",
            borderTopRightRadius: "16px",
            maxHeight: "98%",
            width: "90%",
            margin: "0 auto",
            display: "flex",
            justifySelf: "center",
          },
        }}
      >
        <CreateSupplierForm handleDrawerClose={handleDrawerClose} />
      </Drawer>
    </Box>
  );
};

export default SupplierTableDataGrid;
