import React, { useMemo } from "react";
import { Typography, Grid, Box } from "@mui/material";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  selectFactoryActionNeededData,
  selectFactoryData,
} from "../../selectors/factory.selector";
import { selectAuditListForFactory, selectCapListForFactory } from "../../selectors/audit.selector";
import { FacilityStyles } from "../../styles";

import FactoryActionNeeded from "./factoryActionNeeded.component";
import FactoryMostRecentAuditDetails from "./factoryMostRecentAuditDetails.component";
import FactoryRiskCard from "./factoryRiskCard.component";

const FacilityComplianceStatus = () => {
  const params = useParams();
  const selectFactory = useSelector(selectFactoryData);
  const selectFactoryActionNeeded = useSelector(selectFactoryActionNeededData);

  const factoryCapData = useSelector((state) =>
    selectCapListForFactory(state, params.factoryId)
  );

  // Filter out items with status "Open"
  const openCapData = factoryCapData
    .filter(
      (item) => item.status === "Open" && item.severity !== "N/A"
    )
    .map((item) => ({
      ...item,
      severity: item.severity === "N/A" ? "Not Specified" : item.severity,
    }));

  // Define the multipliers
  const multipliers = {
    Extreme: 1.5,
    Critical: 0.9,
    Major: 0.15,
    Moderate: 0.06,
    Observation: 0.03,
  };

  const { enhancedRisk, enhancedRiskScore } = useMemo(() => {
    const categories = [
      {
        label: "Extreme",
        count: parseInt(selectFactory?.issueDetails?.Extreme || "0", 10) || 0,
      },
      {
        label: "Critical",
        count: parseInt(selectFactory?.issueDetails?.Critical || "0", 10) || 0,
      },
      {
        label: "Major",
        count: parseInt(selectFactory?.issueDetails?.Major || "0", 10) || 0,
      },
      {
        label: "Moderate",
        count: parseInt(selectFactory?.issueDetails?.Moderate || "0", 10) || 0,
      },
      {
        label: "Observation",
        count: parseInt(selectFactory?.issueDetails?.Observation || "0", 10) || 0,
      },
    ];

    // Calculate the enhancedRiskScore
    const enhancedRiskScore = categories.reduce((acc, category) => {
      return acc + category.count * (multipliers[category.label] || 0);
    }, 0);

    const enhancedRisk = categories.map((category) => ({
      label: category.label,
      subLabel: category.count.toString(),
      value: (category.count * (multipliers[category.label] || 0)).toFixed(2)
    }));

    return { enhancedRisk, enhancedRiskScore };
  }, [selectFactory]);

  return (
    <>
      <Box>
        {openCapData?.length > 0 && (
          <FactoryActionNeeded capDetails={openCapData} />
        )}
      </Box>
      <Typography sx={FacilityStyles.mostRecentAuditHeading}>
        Risk Score
      </Typography>
      <Grid container spacing={4} style={{ marginTop: -16 }}>
        <Grid item xs={6}>
          <FactoryRiskCard
            key={"inherentRisk"}
            title={"Abstract Risk"}
            riskScore={
              selectFactory?.metadata?.inherentRisk?.totalScore === "" ? "No Data" : (selectFactory?.metadata?.inherentRisk?.totalScore ?? "No Data")
            }
            
            details={[
              {
                label: "Country",
                subLabel: selectFactory?.metadata?.inherentRisk?.country || "-",
                value: selectFactory?.metadata?.inherentRisk?.countryScore ?? "-",
              },
              {
                label: "Sourcing TML",
                subLabel: selectFactory?.metadata?.inherentRisk?.sourcingTml || "-",
                value: selectFactory?.metadata?.inherentRisk?.sourcingTmlScore ?? "-",
              },
              {
                label: "Audit Type",
                subLabel: selectFactory?.metadata?.inherentRisk?.auditType || "-",
                value: selectFactory?.metadata?.inherentRisk?.auditTypeScore ?? "-",
              },
              {
                label: "Frequency of Monitoring",
                subLabel: selectFactory?.metadata?.inherentRisk?.frequency || "-",
                value: selectFactory?.metadata?.inherentRisk?.frequencyScore ?? "-",
              },
            ]}
          />
        </Grid>
        <Grid item xs={6}>
          <FactoryRiskCard
            key={"enhancedRisk"}
            title={"Enhanced Risk"}
            riskScore={
              selectFactory?.latestAudit?.risk?.auditScore ??
              enhancedRiskScore ??
              "Coming Soon"
            }
            details={enhancedRisk}
          />
        </Grid>
      </Grid>

      {selectFactory?.latestAudit ? (
        <FactoryMostRecentAuditDetails selectedFactory={selectFactory} />
      ) : (
        <>
        <Typography sx={FacilityStyles.mostRecentAuditHeading}>
        Details from Most Recent Audit
        </Typography>
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "50px",
          }}
        >
          
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: 16,
              color: "#8B8D97",
            }}
          >
            No audit exist for factory status
          </Typography>
        </Box>
        </>
      )}
    </>
  );
};

export default FacilityComplianceStatus;
